import React from 'react';
import { useQuery } from 'urql';
import { useMediaQuery } from 'react-responsive';
import SideBarItem from '../../Parts/Content/SideBar/SideBarItem';

const LivesQuery = `
    {
        lives(orderBy: title_ASC) {
            title
            start
            url
            end
        }
    }
`;

const Online = () => {

    const [result] = useQuery({ query: LivesQuery });
    const { data, fetching, error } = result;

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    if (fetching) return <p>Pobieram dane o liveach...</p>
    if (error) return <p>O nie... {error.message}</p>

    const livesArray = data?.lives.map((obj, index) => {
        return {
            id: index,
            title: obj.title,
            url: obj.start.replace(/\\/g, '') + obj.url.replace(/\\/g, '') + obj.end.replace(/\\/g, '')
        }
    })

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "20px" }}>
                    <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                        <div className='flex justify-content-center'>
                            <SideBarItem iframe='<iframe title="" src="https://calendar.google.com/calendar/embed?showTabs=1&height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FWarsaw&src=MDZscnEyZWY3M3FxZWsxc3VrYTBlNzFzNjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23616161&mode=AGENDA" width="90%" height="300" frameborder="0" scrolling="no"></iframe>'
                            />

                            <SideBarItem iframe='<iframe src="https://www.google.com/calendar/embed?showTabs=1&mode=AGENDA&height=600&wkst=1&bgcolor=%23FFFFFF&src=rgaockojes7e29m8tvr75q4ut4%40group.calendar.google.com&color=%23182C57ctz=Europe%2FWarsaw" scrolling="no" width="90%" height="300" frameBorder="0"></iframe>'
                            />
                        </div>
                        <div className="border-top my-2"></div>
                        {livesArray.map((item) => {
                            return (
                                <div key={item.title}>
                                    <SideBarItem title={item.title} iframe={item.url} />
                                    <div className="border-top my-2"></div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div style={{ paddingLeft: "20px", marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "20px" }}>
                    <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                        <SideBarItem iframe='<iframe title="" src="https://calendar.google.com/calendar/embed?showTabs=1&height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FWarsaw&src=MDZscnEyZWY3M3FxZWsxc3VrYTBlNzFzNjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23616161&mode=AGENDA" width="100%" height="250" frameborder="0" scrolling="no"></iframe>'
                            title="Msze Święte" />
                        <div className="border-top my-2"></div>
                        <SideBarItem iframe='<iframe src="https://www.google.com/calendar/embed?showTabs=1&mode=AGENDA&height=600&wkst=1&bgcolor=%23FFFFFF&src=rgaockojes7e29m8tvr75q4ut4%40group.calendar.google.com&color=%23182C57ctz=Europe%2FWarsaw" scrolling="no" width="100%" height="250" frameBorder="0"></iframe>'
                            title="Nabożeństwa" />
                        <div className="border-top my-2"></div>
                        {livesArray.map((item) => {
                            return (
                                <div key={item.title}>
                                    <SideBarItem style={{ marginTop: "10px", marginBottom: "20px" }} title={item.title} iframe={item.url} />
                                    <div className="border-top my-2"></div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </>
    );

}

export default Online;