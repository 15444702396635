import { React, useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = (props) => {
  const [photosUrls, setPhotosUrls] = useState([]);

  useEffect(() => {
    if (props.gallery?.photos) {
      const array = props.gallery?.photos.map((obj, index) => {
        return {
          id: index,
          original: obj.url,
          thumbnail: obj.url
        }
      })
      setPhotosUrls(array)
    }
  }, [props.gallery?.photos])

  return (
    <div style={props.style}>
      {photosUrls.length > 0 &&
        <ImageGallery
          items={photosUrls}
          slideOnThumbnailOver={true}
        />
      }
    </div>
  );
}

export default Gallery;