import React, { useState, useEffect } from 'react';
import { useQuery } from "urql";
import styles from "./Gallery.module.css";
import { useMediaQuery } from 'react-responsive';
import Gallery from './Gallery';
import { Dropdown } from 'primereact/dropdown';
import { ListBox } from 'primereact/listbox';


const GalleriesQuery = `
    query {
        galeries(orderBy: dodano_DESC){
        tytul
        photos {
            url
        }
        thumbnails {
            url
        }
        slug
        }
    } 
`;

const Galleries = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedPictureObj, setSelectedPictureObj] = useState();
    const [items, setItems] = useState([]);

    const [result] = useQuery({ query: GalleriesQuery });
    const { data, fetching, error } = result;

    const getPictureObj = (id) => {
        setSelectedPictureObj(data?.galeries[id])
    }

    useEffect(() => {
        const getPictures = async () => {
            setItems(data?.galeries?.map((obj, i) => ({ label: obj.tytul, value: i })))
        }

        getPictures();
        setSelectedPictureObj(data?.galeries[selectedValue])
    }, [data, selectedValue])

    if (fetching) return <p>Pobieram galerie...</p>
    if (error) return <p>O nie... {error.message}</p>

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
                    <div style={{ marginTop: "10px", marginLeft: "30px", marginRight: "30px" }}>
                        <div style={{ display: "flex", alignContent: "space-between" }}>
                            <Gallery style={{ width: "80%" }} gallery={selectedPictureObj} />
                            <div className={styles.card + " flex justify-content-end"}>
                                <ListBox
                                    filter
                                    value={selectedValue}
                                    onChange={(e) => {
                                        setSelectedValue(e.value)
                                        getPictureObj(e.value)
                                    }}
                                    options={items}
                                    virtualScrollerOptions={{ itemSize: 40 }}
                                    listStyle={{ height: "550px" }}
                                    style={{ marginLeft: "30px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div styles={{ width: "470px" }}>
                    <div >
                        <div>
                            <Dropdown
                                filter
                                value={selectedValue}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    getPictureObj(e.value)
                                }}
                                options={items}
                                style={{ marginLeft: "30px" }}
                            />
                        </div>
                    </div>
                    <Gallery style={{ width: "45%", paddingLeft: "20px" }} gallery={selectedPictureObj} />
                </div>
            }
        </>
    );
}

export default Galleries;