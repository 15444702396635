import { React, useEffect, useState } from 'react';
import { gql, useQuery } from 'urql';
import { useMediaQuery } from 'react-responsive';
import ReactHtmlParser from 'react-html-parser';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from "primereact/dropdown";

import styles from './Actuals.module.css';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ActualsQuery = gql`
    {
        aktualnoscis(orderBy: dodano_DESC){
            tytul
            dodano
            createdBy {
                name
            }
            slug
            zawartosc {
                html
            }
            photos{
                url
            }
        }
    }
`;

const Actuals = () => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedActualObj, setSelectedActualObj] = useState();
    const [items, setItems] = useState([]);
    const [photosUrls, setPhotosUrls] = useState([]);

    const [result] = useQuery({
        query: ActualsQuery
    })

    const { data, fetching, err } = result;


    const getActualObj = (id) => {
        setSelectedActualObj(data?.aktualnoscis[id])
    }

    useEffect(() => {
        const getActuals = async () => {
            setItems(data?.aktualnoscis?.map((obj, i) => ({
                label: obj.tytul?.length > 30 ? obj.tytul.substring(0, 30) + ` ...` : obj.tytul,
                value: i
            })))
        }

        setSelectedActualObj(data?.aktualnoscis[selectedValue])
        getActuals();

        if (data?.aktualnoscis[selectedValue].photos) {
            const array = data?.aktualnoscis[selectedValue].photos.map((obj, index) => {
                return {
                    id: index,
                    original: obj.url
                }
            })
            setPhotosUrls(array)
        }
    }, [data, selectedValue])

    if (fetching) return <p>Pobieram informacje z wpisu...</p>
    if (err) return <p>O nie... {err.message}</p>

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
                    <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <div style={{ marginLeft: "30px", marginRight: "30px", width: "100%" }}>
                            {ReactHtmlParser(selectedActualObj?.zawartosc?.html)}
                        </div>
                        <div className={styles.card + " flex justify-content-end"}>
                            <ListBox
                                className="p-listbox w-full md:w-16rem"
                                filter
                                value={selectedValue}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    getActualObj(e.value)
                                }}
                                options={items}
                                listStyle={{ height: "100%" }}
                                style={{ marginRight: "15px", width: "300px" }}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: "11px" }}>
                        {selectedActualObj?.photos.length === 1 && <img style={{ width: "600px", height: "600px", marginBottom: "11px", paddingLeft: "11px", paddingRight: "11px" }} src={selectedActualObj?.photos[0].url} alt="aktualnosc" />}
                        {selectedActualObj?.photos.length > 1 &&
                            <ImageGallery
                                items={photosUrls}
                                showFullscreenButton={false}
                                showBullets={true}
                                showPlayButton={false}
                            />
                        }
                        {selectedActualObj?.photos.length === 0 && null}
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div className="flex justify-content-center" style={{ paddingLeft: "20px", marginTop: "10px", width: "100%", flexDirection: "column" }}>
                    <div className={styles.card + " flex justify-content-center"}>
                        <Dropdown
                            className="w-full md:w-14rem"
                            filter
                            value={selectedValue}
                            onChange={(e) => {
                                setSelectedValue(e.value)
                                getActualObj(e.value)
                            }}
                            options={items}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        {ReactHtmlParser(selectedActualObj?.zawartosc.html)}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {selectedActualObj?.photos.length === 1 && <img style={{ width: "100%", paddingLeft: "20px" }} src={selectedActualObj?.photos[0].url} alt="aktualnosc" />}
                        {selectedActualObj?.photos.length > 1 &&
                            <ImageGallery
                                items={photosUrls}
                                showFullscreenButton={false}
                                showBullets={true}
                                showPlayButton={false}
                            />
                        }
                        {selectedActualObj?.photos.length === 0 && null}
                    </div>
                </div>
            }
        </>
    )
}

export default Actuals