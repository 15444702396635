import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createClient, Provider } from 'urql';
import { CookiesProvider } from "react-cookie";
import { PrimeReactProvider } from 'primereact/api';
import ReactGA from "react-ga";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
// import env from 'react-dotenv';

const client = createClient({
  url: process.env.REACT_APP_GRAPH_ENDPOINT,
});

const trackingCode = process.env.REACT_APP_TRACKING_CODE;

ReactDOM.render(
  <React.StrictMode>
    <PrimeReactProvider value={{ ripple: true, inputStyle: 'outlined', cssTransition: true }}>
      <Provider value={client}>
        <CookiesProvider>
          <App>
            {
              ReactGA.initialize(trackingCode)
            }
          </App>
        </CookiesProvider>
      </Provider>
    </PrimeReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
