import { React, useState, useEffect } from 'react';
import { gql, useQuery } from 'urql';
import { useMediaQuery } from 'react-responsive';
import { Document, Page, pdfjs } from 'react-pdf';
import { Dropdown } from "primereact/dropdown";
import { ListBox } from 'primereact/listbox';

const IntentionsQuery = gql`
{
    intencjes(orderBy: dodano_DESC){
      tytul
      slug
      plikPdf{
      url
      }
    }
  }
`;

const Intentions = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedIntentionObj, setSelectedIntentionObj] = useState();
    const [items, setItems] = useState([]);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [result] = useQuery({ query: IntentionsQuery })
    const { data, fetching, error } = result;

    const getIntencjesObj = (id) => {
        setSelectedIntentionObj(data?.intention[id])
    }

    useEffect(() => {
        const getIntencjes = async () => {
            setItems(data?.intencjes?.map((obj, i) => ({
                label: obj.tytul.length > 30 ? obj.tytul.substring(0, 30) + ` ...` : obj.tytul,
                value: i
            })))
        }

        setSelectedIntentionObj(data?.intencjes[selectedValue])
        getIntencjes();
    }, [data, selectedValue]);

    if (fetching) return <p>Pobieram intencje...</p>
    if (error) return <p>O nie... {error.message}</p>

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const previousPage = () => setPageNumber(pageNumber - 1);
    const nextPage = () => setPageNumber(pageNumber + 1);

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px", flexDirection: "row" }}>
                    <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <div style={{ marginLeft: "30px", marginRight: "30px", width: "80%" }}>
                            <>
                                <div key={selectedIntentionObj?.slug}>
                                    <h1>{selectedIntentionObj?.tytul}</h1>
                                    <div style={{ width: "100%" }}>
                                        <Document
                                            file={selectedIntentionObj?.plikPdf.url}
                                            options={{ workerSrc: "/pdf.worker.js" }}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={pageNumber}
                                                width="1000"
                                            />
                                        </Document>
                                    </div>
                                    <div>
                                        <p>
                                            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                        </p>
                                        <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                            Poprzednia
                                        </button>
                                        <button
                                            className="button"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Następna
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                        <div className={"card flex "}>
                            <ListBox
                                filter
                                value={selectedValue}
                                options={items}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    getIntencjesObj(e.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <>
                    <div style={{ paddingLeft: "20px", width: "100%" }}>
                        <Dropdown
                            filter
                            value={selectedValue}
                            options={items}
                            onChange={(e) => {
                                setSelectedValue(e.value)
                                getIntencjesObj(e.value)
                            }}
                        />
                        <>
                            <div key={selectedIntentionObj?.slug}>
                                <h1>{selectedIntentionObj?.tytul}</h1>
                                <div style={{ width: "100%" }}>
                                    <Document
                                        file={selectedIntentionObj?.plikPdf.url}
                                        options={{ workerSrc: "/pdf.worker.js" }}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber}
                                            width="300"
                                        />
                                    </Document>
                                </div>
                                <div style={{ justifyContent: "center" }}>
                                    <p>
                                        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                    </p>
                                    <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                        Poprzednia
                                    </button>
                                    <button
                                        className="button"
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        Następna
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </>
            }
        </>
    );
}

export default Intentions;